import {
  ADS_ENABLED,
  AD_URL,
  AD_URL2,
  AD_OPEN_INTERVAL,
  AD_PWA_OPEN_INTERVAL,
} from 'config';
import { openURL } from 'lib/url';
import { isStandalone } from 'lib/devices';

let selectedURL = null;
function nextURL() {
  // Select a random link first.
  if (selectedURL == null) {
    selectedURL = randomURL();
    return selectedURL;
  }
  // Alternate between the links.
  selectedURL = selectedURL === AD_URL ? AD_URL2 : AD_URL;

  return selectedURL;
}

function randomURL() {
  let randomValue;

  // Check if crypto.getRandomValues is supported.
  if (window.crypto && window.crypto.getRandomValues) {
    const randomBuffer = new Uint8Array(1);
    window.crypto.getRandomValues(randomBuffer);
    // Secure random number (0-255)
    randomValue = randomBuffer[0];
  } else {
    // Fallback for old browsers.
    randomValue = Math.floor(Math.random() * 256);
  }

  // Compare to 128 (midpoint of 0-255 range) for a 50/50 split.
  return randomValue < 128 ? AD_URL : AD_URL2;
}

export function openAd() {
  if (ADS_ENABLED) {
    return openURL(
      nextURL(),
      // AD_URL,
      isStandalone ? AD_PWA_OPEN_INTERVAL : AD_OPEN_INTERVAL
    );
  }

  return false;
}
